import React from 'react'
import { GridItemWithImage } from '../../Global/Content/OffsetGrid'
import { useStaticQuery, graphql } from 'gatsby'

const AwardNights = () => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "awards-night.jpg" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 630, maxHeight: 420) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <GridItemWithImage
      title="Awards Nights"
      sources={image.childImageSharp.fluid}
      imageAlt="Executive travel to awards nights with Chauffeur Me."
    />
  )
}

export default AwardNights