import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Layout from '../../components/Layout'
import LeftWithImage from '../../components/Global/Hero/LeftWithImage'
import { YellowButton } from '../../components/Global/Button'
import SEO from '../../components/Global/SEO'
import { Centered2x2GridWrapper, GridWrapper, GridItem } from '../../components/Global/Content/Centered2x2Grid'
import SectionTitle from '../../components/Global/SectionTitle'
import { OffsetGridWrapper } from '../../components/Global/Content/OffsetGrid'
import { EmojiHappy, User, Sparkles, LockClosed } from '../../components/Global/Icons'
import { Conferences, Meetings, NetworkingEvents, MediaInterviews, TradeShows, AwardNights } from '../../components/Services/Corporate'

const CorporateTravelPage = ({ location }) => {
  const { seoImg, corporate } = useStaticQuery(graphql`
    query {
      seoImg: file(relativePath: { eq: "corporate-events-travel.jpg" }) {
        childImageSharp {
          fixed(quality: 90, width: 1200, height: 630) {
            src
          }
        }
      }
      corporate: file(relativePath: { eq: "corporate-events-travel.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 900, maxHeight: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <Layout>

      <SEO
        title="Corporate Travel | Meetings, Conferences, Award Nights, Exhibitions"
        description="We work with companies and their business personnel to provide an affordable, stress-free airport and long distance chauffeur service to meetings, exhibitions and hospitality events."
        image={seoImg.childImageSharp.fixed.src}
        pathname={location.pathname}
      />

      <LeftWithImage
        image={corporate.childImageSharp.fluid}
        imageAlt="Corporate Events Travel"
      >
        <div className="text-sm font-semibold uppercase tracking-wide text-gray-500 sm:text-base lg:text-sm xl:text-base">
          Services
        </div>
        <h2 className="mt-1 text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:leading-none sm:text-6xl lg:text-5xl xl:text-6xl">
          Corporate Events &amp; 
          <br className="hidden md:inline" />
          <span className="text-yellow-300"> Business Travel</span>
        </h2>
        <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
          We work with companies and their business personnel to provide an affordable, stress-free airport and long distance chauffeur service to meetings, exhibitions and hospitality events.
        </p>
        <YellowButton to="/book-now" className="inline-block mt-8">Book Now</YellowButton>
      </LeftWithImage>

      <Centered2x2GridWrapper>
        <SectionTitle
          title="Business Travel Made Easy"
          text="Travelling in the comfort of our chauffeur driven vehicles provides a relaxing and stress free environment for any busy business executives."
        />
        <GridWrapper>
          <GridItem
            title="Return Travel"
            description="We are happy to complete both one way and return journeys, whether you are travelling for a business function, special occasion or any other event."
            icon={<EmojiHappy />}
          />
          <GridItem
            title="Professional Chauffeurs"
            description="Our chauffeurs are fully qualified and provide a courteous service throughout the journey, from when we pick you up to when we drop you off."
            icon={<User />}
          />
          <GridItem
            title="Luxury Fleet"
            description="Our fleet contains luxury Mercedes saloons for up to 4 passengers and a luxurious 7 passengers for if there are more passengers travelling."
            icon={<Sparkles />}
          />
          <GridItem
            title="Confidentiality &amp; Privacy"
            description="Our corporate chauffeur services are perfect for if your organisation or company requires exclusive transportation services that are completely confidential."
            icon={<LockClosed />}
          />
        </GridWrapper>
      </Centered2x2GridWrapper>

      <OffsetGridWrapper title="Tailored Business Chauffeur Services">
        <Meetings />
        <Conferences />
        <NetworkingEvents />
        <MediaInterviews/>
        <TradeShows />
        <AwardNights />
      </OffsetGridWrapper>

    </Layout>
  )
}

export default CorporateTravelPage