import React from 'react'
import { GridItemWithImage } from '../../Global/Content/OffsetGrid'
import { useStaticQuery, graphql } from 'gatsby'

const TradeShows = () => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "trade-show.jpg" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 630, maxHeight: 420) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <GridItemWithImage
      title="Trade Shows"
      sources={image.childImageSharp.fluid}
      imageAlt="Executive travel to trade shows with Chauffeur Me."
    />
  )
}

export default TradeShows